/* Moxi Orange #d17a53 */
html { 
    background: url(../public/output-onlinepngtools.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  } 

/* --------------------------Header-------------------------- */
.logo {
    display: block;
    margin: 0 auto;
    width: min(85%, 750px);
 }
 .header {
     width: 100% vw;
     padding: max(3vh, 1rem);
 }
 .links {
    font-family: 'Raleway', sans-serif;
    font-size: 1.4em;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: black;
    background-image: linear-gradient(to right, rgba(255,0,0,0), #8EA487, rgba(255,0,0,0));
 }
 .link{
     text-decoration: none;
     box-shadow: inset 0 0 0 0 black;
     color: black;
     margin: 0 -.25rem;
     padding: 0 .25rem;
     transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
     border-radius: 15px;
    }
 .link:hover{
    box-shadow: inset 100px 0 0 0 #d17a53;

 }

/* --------------------------Main Page-------------------------- */
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 90%;
 }
 .title{
    font-size: 1.8em;
    font-family: 'La Belle Aurore', cursive;
 }
 .mission {
    font-size: 1.2em;
    font-family: 'Raleway', sans-serif;
    position: relative;
    text-align: center;
    margin-bottom: 1.5em;
}
.definitions{
    display: flex;
    flex-direction: column;
}
.moxieDefinition{
    width: 90%;
    border: solid #8EA487;
    margin: auto;
    margin-bottom: 1em;
}
.unconditionalLove{
    width: 90%;
    border: solid #8EA487;
    margin: auto;
}
.mainDonateButton {
    text-align: center;
    transition: .3s;
    margin: .5em;
    font-family: 'Raleway', sans-serif;
    font-size: 1.3em;
}
/* .mainDonateButton:hover{
    background-color: #d17a53;
} */
.statement{
    font-family: 'Raleway', sans-serif;
    text-indent: 5vw;
    font-size: 1.2em;
}
/* --------------------------About Page-------------------------- */
.aboutDiv {
    width: min(90%, 1000px);
    margin-inline: auto;
    font-family: 'Raleway', sans-serif;
    text-indent: 8vw;
    display: flex;
    flex-direction: column;
 }

 .buniImage {
     width: 88vw;
    display: block;
    margin-inline: auto;
    border-radius: 15px;
    border: 3px solid black;
}
.buniSig {
    font-size: 1.8em;
    font-family: 'Kristi', cursive;
    text-align: end;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
h4{
    text-align: end;
    margin: 0;
    padding: 0;
    
}
/* --------------------------Contact-------------------------- */
.contact {
    font-size: 1.2em;
    font-family: 'Raleway', sans-serif;
    position: relative;
    text-align: center;
    margin-bottom: 1.5em;
}
.contactIcons{
    /* margin: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 .contactIcons a {
    font-size: 1.5em;
    color: black;
    margin-bottom: 1em;
} 
.socialIcon{
    max-width: 200px;
    max-height: 200px;
    margin: 0 auto;
}
.email{
    color: black;
    font-size: 1.1em;
}
.fb{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
.insta{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
.tw{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
/* --------------------------Donate-------------------------- */
.donate{
    display: flex;
    flex-direction: column;
    margin: 1em;
    font-family: 'Raleway', sans-serif;
    font-size: 1.1em;
    text-indent: 1em;
    justify-content: center;
    margin-bottom: 5em;
}
.donate p {
    margin-bottom: 2em;
}
.donate a {
    text-align: center;
    padding: 15px;
}
.donateButton{
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 1.5em;
    border-radius: 30px;
    background-color: #8EA487;
    justify-content: center;
}

/* --------------------------Footer-------------------------- */
.footer {
    display: flex;
    justify-content: space-around;
}
.footer a {
    color: black;
    font-size: 1.5em;
}

@media screen and (min-width: 768px){
/* --------------------------Main Page-------------------------- */
    .main{
        margin: auto;
        width: 85%;
    }    
    .title{
        font-size: 3em;
        font-family: 'La Belle Aurore', cursive;
     }
     .definitions{
         flex-direction: row;
     }
    .moxieDefinition{
        margin: auto;
        width: 45%;
        border: solid #8EA487;
    }
    .unconditionalLove{
        margin: auto;
        width: 45%;
        border: solid #8EA487;
    }
    .mainDonateButton {
        margin: 0 auto;
        margin-bottom: 1em;
    }
  
/* --------------------------Header-------------------------- */
    .logo {
     margin: 0 auto;
     width: min(60%, 650px);
    }
    .links {
     justify-content: space-around;
        }
/* --------------------------About Page-------------------------- */
 .buniImage {
    width: 50vw;
}
.aboutDiv {
    width: min(75%, 1000px);
    font-size: 1.2em;
    text-indent: 5vw;
    display: flex;
    flex-direction: column;
 
 }
.buniSig {
    font-size: 2em;
}

.buniStoryButton{
    font-size: 1em;
    margin-top: .5em;
    width: max(300px);
}
/* --------------------------Donate-------------------------- */
.donate{
    width: min(85%);
    margin-inline: auto;
}
.donateButton{
    margin: 0 auto;
    width: 50%;
}
/* --------------------------Contact-------------------------- */
.contactIcons{
    flex-direction: row;
}
.fb{
    /* border: red 1px solid; */
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
.insta{
    /* border: black 1px solid; */
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
.tw{
    /* border: red 1px solid; */
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    font-size: .8em;
}
}
@media screen and (min-width: 1024px){
/* -----------------Header---------------- */
.logo{
    width: 50%;
}

/* -----------------Main---------------- */
.main{
    margin: auto;
    width: 80%;
}  

/* -----------------Donate---------------- */
.donate{
    width: 75%;
}
/* -----------------About---------------- */
.buniImage{
    max-width: 40%;
}

}